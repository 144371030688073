import * as React from "react"
import PropTypes from "prop-types"
import logoPink from "../images/made-logo.png"
import LinkWrapper from "./link-wrapper"
import { useState } from "react"

const events = [
  {
    name: "BIG Dream Gathering",
    link: "https://www.eventbrite.com/e/dream-big-earlham-tickets-151246037971",
  },
]

const Header = ({ siteTitle }) => {
  const [showNav, setShowNav] = useState(false)

  return (
    <nav
      className="navbar is-transparent is-fixed-top has-text-weight-bold is-spaced"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand is-large">
          <LinkWrapper to="/" className="navbar-item">
            <img src={logoPink} />
          </LinkWrapper>
          <div className="navbar-burger" onClick={() => setShowNav(!showNav)}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          className={`navbar-menu has-text-weight-light ${
            showNav ? "is-active" : ""
          }`}
        >
          <div className="navbar-start" />
          <div className="navbar-end">
            <LinkWrapper to="#about" className="navbar-item">
              About
            </LinkWrapper>
            <LinkWrapper to="#services" className="navbar-item">
              Services
            </LinkWrapper>
            <LinkWrapper to="/gallery" className="navbar-item">
              Gallery
            </LinkWrapper>
            <div className="navbar-item has-dropdown is-hoverable">
              <LinkWrapper to="/events" className="navbar-link">
                Events
              </LinkWrapper>
              <div className="navbar-dropdown">
                {events.map((event, i) => (
                  <LinkWrapper to={event.link} className="navbar-item">
                    {event.name}
                  </LinkWrapper>
                ))}
              </div>
            </div>
            <LinkWrapper to="/contact" className="navbar-item">
              <button className={`button is-rounded is-info`}>Contact</button>
            </LinkWrapper>
          </div>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
