import * as React from "react"
import { Link } from "gatsby"

// Hoping this would make it easier to move away from gatsby if needed
const LinkWrapper = ({ to, alt, className, children }) => (
  <Link to={to} alt={alt || ""} className={className}>
    {children}
  </Link>
)

export default LinkWrapper
